import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import HTMLReactParser from "html-react-parser"
import { getValueD9, renameImage, getDescription, stripHtml } from "../utils"
import SocialSharing from "../components/SocialSharing"
import Documents from "../components/Documents"
const PageTemplate = ({ data, ...props }) => {
  const image = renameImage(
    getValueD9(
      data.nodeSsEvent,
      "relationships.field_cover.relationships.field_media_image.uri.value",
      false
    ),
    null,
    "photo_galerie_paysage"
  )
  const body = getValueD9(data.nodeSsEvent, "body.processed", "")
  return (
    <Layout title={data.nodeSsEvent.title} {...props} description={getDescription(stripHtml(body))}>
      {image !== false && (
        <div className="uk-cover-container uk-height-medium">
          <img src={image} alt={data.nodeSsEvent.title} uk-cover="" />
        </div>
      )}

      <div className="uk-section uk-padding uk-background-default">
        <p>
          {"Du " +
            getValueD9(
              data.nodeSsEvent,
              "relationships.field_event_collection.0.field_dates.value",
              ""
            ) +
            " au " +
            getValueD9(
              data.nodeSsEvent,
              "relationships.field_event_collection.0.field_dates.end_value",
              ""
            ) +
            " - " +
            getValueD9(
              data.nodeSsEvent,
              "relationships.field_event_collection.0.relationships.field_commune.name",
              ""
            )}
        </p>
        {HTMLReactParser(body)}
        <SocialSharing />
        <Documents data={getValueD9(data.nodeSsEvent, "relationships.field_files", [])} />
      </div>
    </Layout>
  )
}
export default PageTemplate

export const query = graphql`
  query Event_ByID($id: String!) {
    nodeSsEvent(id: { eq: $id }) {
      id
      title
      body {
        processed
      }
      relationships {
        field_files {
          relationships {
            field_media_document {
              filename
              uri {
                url
                value
              }
            }
          }
        }
        field_event_collection {
          field_dates {
            end_value(formatString: "DD/MM/YYYY")
            value(formatString: "DD/MM/YYYY")
          }
          relationships {
            field_commune {
              name
            }
          }
        }
        field_cover {
          relationships {
            field_media_image {
              url
              uri {
                url
                value
              }
            }
          }
        }
      }
    }
  }
`
